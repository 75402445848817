import axios from 'axios'
// import LoggerService from '@/services/LoggerService'

// const logger = LoggerService.getLogger('HttpClient')
const logoutErrorStatus = [401]
const instance = axios.create()

const bearer = function(config) {
  if (
    window.localStorage.getItem('auth_token') &&
    config.url.indexOf('http') === -1
  ) {
    config.headers.Authorization = `${window.localStorage.getItem(
      'auth_token'
    )}`
    config.url = `https://integracion.mobysuite.com/api/v1/${config.url}`
  } else {
    config.url = `https://integracion.mobysuite.com/api/v1/${config.url}`
  }
  // logger.trace(config)
  return config
}
instance.interceptors.request.use(bearer)

instance.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status == logoutErrorStatus) {
      window.location.href = '/login?session=expired'
    }
    return Promise.reject(error)
  }
)

export default instance
