<template>
  <div :class="{ active: activeMessage }" class="sideBar__item">
    <table border="0" style="width: 100%">
      <tr>
        <td v-if="clientName" class="">
          <div class="--senderName">{{ clientName }}</div>
          <small class="--senderName">+{{ userName }}</small>
        </td>
        <td v-else class="">
          <div class="--senderName">+{{ userName }}</div>
        </td>
        <td class="sideBar__senderTime">
          <span class="--senderTime">{{
            lastMessageTime
              ? new Date(lastMessageTime).toLocaleString()
              : 'No hay mensajes'
          }}</span>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="sideBar__senderContent">
          <span v-if="lastMessage" class="--senderMessage"
            >{{ lastMessage.substring(0, 70)
            }}{{ lastMessage.length > 70 ? '...' : '' }}</span
          >
        </td>
      </tr>
    </table>

    <div v-if="unreadMessages > 0" class="unread_message">
      {{ unreadMessages }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    userName: { type: String, default: null },
    clientName: { type: String, default: null },
    lastMessage: { type: String, default: null },
    activeMessage: { type: Boolean, default: null },
    userPhoto: { type: String, default: null },
    lastMessageTime: { type: String, default: null },
    unreadMessages: { type: Number, default: 0 },
  },
}
</script>
