<template>
  <WhatsappComponent
    :executive-name="executiveName"
    :executive-phone="executivePhone"
    :executive-id="executiveId"
    :customer-id="customerId"
    :dark-mode-parent="darkMode"
    :massive="massive"
  />
</template>
<script>
import WhatsappComponent from './components/WhatsappComponent.vue'
export default {
  name: 'App',
  components: {
    WhatsappComponent,
  },
  props: {
    executivePhone: { type: Number, default: null },
    executiveId: { type: Number, default: null },
    customerId: { type: Number, default: null },
    executiveName: { type: String, default: null },
    darkMode: { type: Boolean, default: false },
    massive: { type: Boolean, default: false },
  },
}
</script>
<style lang="scss">
@import 'assets/sass/style';
</style>
