export function isRutValid(value) {
  if (value == null) return false
  if (value.length === 0) return false
  var input = value.replace(/[^\dKk]/g, '')
  let dato = input
  let largo = dato.length
  if (largo === 1) {
    let dv = dato
    let rut = ''
    return digito(rut, dv)
  } else if (largo > 1) {
    let dv = dato.substring(largo - 1)
    let rut = dato.substring(largo - 1, 0)
    return digito(rut, dv)
  } else {
    return false
  }

  function digito(rut, dv) {
    let suma = 0
    let multiplicador = 2
    while (rut !== 0) {
      if (multiplicador === 8) {
        multiplicador = 2
      }
      suma += (rut % 10) * multiplicador
      rut = Math.trunc(rut / 10)
      multiplicador++
    }
    suma = 11 - (suma % 11)
    switch (suma) {
      case 11:
        return '0' === dv
      case 10:
        return 'K' === dv.toUpperCase()
      default:
        return suma.toString() === dv
    }
  }
}
