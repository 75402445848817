import httpAftersale from '@/utils/httpAftersale'
import httpQuote from '@/utils/httpQuote'

export default {
  getRealEstate(customer) {
    return new Promise((resolve, reject) => {
      httpAftersale
        .get(
          `list-real-estate-info-with-virtual-sale-room?customer_id=${customer}`
        )
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  setProjects(customer, version) {
    return new Promise((resolve, reject) => {
      httpQuote
        .get(version + '/quotes/list-projects', {
          params: {
            real_estate: customer,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(err => reject(err))
    })
  },
}
