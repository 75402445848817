import http from '@/utils/http'

export default {
  getMessages(slug, page) {
    return new Promise((resolve, reject) => {
      http
        .get(`rooms/${slug}/messages?page=${page}&size=30`)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  sendBulkMessage(phoneId, template, fileData) {
    return new Promise((resolve, reject) => {
      console.log(fileData)
      const formData = new FormData()
      formData.append('template_id', template)
      formData.append('file', fileData)
      http
        .post(`phones/${phoneId}/bulk_message`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
