<template>
  <div
    v-if="clients"
    style="padding: 0px 10px; width: 240px; word-break: break-word;"
  >
    <div
      v-if="clients.length == 0"
      style="border: 1px solid transparent; border-radius: 10px; padding: 5px; margin-bottom: 10px; background-color: rgb(240 242 245);"
    >
      <b v-if="clientName">{{ clientName }} <br /></b>
      <small>+{{ clientPhone }}</small>
    </div>
    <div
      v-if="clients.length > 1"
      class="alert info"
      style="border-radius: 10px; padding: 10px; margin-bottom: 10px; font-size: 70%;"
    >
      Posees más de un cliente con el mismo número de teléfono, por favor
      selecciona uno para vincularlo a la conversación.
    </div>
    <div
      v-for="(client, key) in clients"
      :key="`client${key}`"
      style="position: relative; border: 1px solid transparent; border-radius: 10px; padding: 5px; margin-bottom: 10px; background-color: rgb(240 242 245);"
    >
      <small>#{{ client.ID_CLIENTE }}</small> <br />
      {{ client.NOMBRE }} {{ client.APELLIDO }}<br />
      <small>+{{ clientPhone }}</small> <br />
      <small>{{ client.EMAIL }}</small> <br />
      <small>{{ client.RUT }}</small> <br />
      <button
        v-if="clients.length > 1"
        type="button"
        class="btn"
        style="position: absolute; right: -1px; top: -1px; border-radius: 10px;"
        @click="updateRoom(client)"
      >
        Vincular
      </button>
    </div>
    <div v-if="clients.length == 0">
      <div
        class="alert info"
        style="border-radius: 10px; padding: 10px; margin-bottom: 10px; font-size: 70%;"
      >
        No hemos encontrado un cliente asociado a este teléfono en Mobysuite. A
        continuación puedes generar un contacto a partir de este número.
      </div>
      <h4>Ingresar contacto</h4>
      <div class="input-form">
        <label for="">
          Nombre
          <input
            v-model.trim="$v.form.name.$model"
            type="text"
            class="w-100 input"
          />
        </label>
        <div v-if="$v.form.name.$error" class="text-sm text-red">
          <div class="error">
            <small>* Apellido debe tener al menos 1 letra</small>
          </div>
        </div>
      </div>
      <div class="input-form">
        <label for="">
          Apellido
          <input
            v-model.trim="$v.form.lastName.$model"
            type="text"
            class="w-100 input"
          />
        </label>
        <div v-if="$v.form.lastName.$error" class="text-sm text-red">
          <div class="error">
            <small>* Apellido debe tener al menos 1 letra</small>
          </div>
        </div>
      </div>
      <div class="input-form">
        <label for="">
          {{ dniName }}
          <input
            v-model.trim="$v.form.rut.$model"
            type="text"
            class="w-100 input"
            :disabled="hideDni"
          />
        </label>
        <div v-if="$v.form.rut.$error" class="text-sm text-red">
          <div class="error"><small>* Rut con formato incorrecto</small></div>
        </div>
      </div>
      <div class="input-form">
        <div>
          <input id="hideDni" v-model="hideDni" type="checkbox" />
          <label for="hideDni">
            No ingresar
            {{ dniName }}</label
          >
        </div>
      </div>
      <div class="input-form">
        <label for="">
          E-Mail
          <input
            v-model.trim="$v.form.email.$model"
            type="text"
            class="w-100 input"
          />
        </label>
        <div v-if="$v.form.email.$error" class="text-sm text-red">
          <div class="error"><small>* Email con formato incorrecto</small></div>
        </div>
      </div>
      <div v-if="!projects || availableProjects.split(',').length == 1">
        <input v-model="projectId" type="hidden" />
      </div>
      <div v-else class="input-form">
        <label for=""> Proyecto </label
        ><select v-model="projectId" class="form-control">
          <option
            v-for="(item, key) in availableProjects.split(',')"
            :key="'project-' + key"
            :value="item"
          >
            Proyecto
            {{
              projects.find(project => project.id == item)
                ? projects.find(project => project.id == item).nombreProyecto
                : item
            }}
          </option>
        </select>
      </div>
      <div class="input-form">
        <button
          type="button"
          class="btn btn-block btn-primary"
          @click="saveLead()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MobysuiteService from '@/services/MobysuiteService'
import RoomService from '@/services/RoomService'
import { isRutValid } from '@/validators'
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  name: 'SideBar',
  props: {
    customer: { type: Number, default: null },
    clientId: { type: Number, default: null },
    clientName: { type: String, default: null },
    clientPhone: { type: String, default: null },
    roomId: { type: String, default: null },
    phone: { type: String, default: null },
    customerVersion: { type: Number, default: null },
    customerCountry: { type: Number, default: null },
    availableProjects: { type: String, default: null },
    projects: { type: Array, default: null },
  },
  data() {
    return {
      clients: null,
      form: {
        dni: this.customerCountry == 1 ? false : true,
        name: '',
        lastName: '',
        rut: null,
        email: '',
      },
      hideDni: false,
      projectId: this.availableProjects.split(',')[0],
    }
  },
  validations() {
    if (this.hideDni == true || this.customerCountry == 2) {
      return {
        form: {
          rut: { required: false },
          name: { required },
          lastName: { required },
          email: { email },
        },
      }
    } else {
      return {
        form: {
          rut: { RutValid: isRutValid },
          name: { required, minLength: minLength(1) },
          lastName: { required, minLength: minLength(1) },
          email: { email },
        },
      }
    }
  },
  computed: {
    dniName() {
      return this.customerCountry == 1 ? 'Rut' : 'DNI'
    },
  },
  watch: {
    clientPhone() {
      this.getClients()
    },
  },
  beforeMount() {
    this.getClients()
  },
  methods: {
    getClients() {
      let self = this
      const phone = self.clientPhone
      if (this.clientId) {
        MobysuiteService.getClientByParams(
          this.customer,
          this.clientId,
          'id'
        ).then(res => {
          self.clients = res
          if (self.clients.length == 1) {
            const client = self.clients[0]
            if (
              self.clientPhone == phone &&
              (self.clientId !== client.ID_CLIENTE ||
                self.clientName !== `${client.NOMBRE} ${client.APELLIDO}`)
            )
              self.updateRoom(client)
          }
        })
      } else {
        MobysuiteService.getClientByParams(
          this.customer,
          this.clientPhone,
          'phone'
        ).then(res => {
          self.clients = res
          if (self.clients.length == 1) {
            const client = self.clients[0]
            if (
              self.clientPhone == phone &&
              (self.clientId !== client.ID_CLIENTE ||
                self.clientName !== `${client.NOMBRE} ${client.APELLIDO}`)
            )
              self.updateRoom(client)
          }
        })
      }
    },
    updateRoom(client) {
      let self = this
      if (client)
        RoomService.updateRoom({
          client_id: client.ID_CLIENTE,
          client_name: `${client.NOMBRE} ${client.APELLIDO}`,
          client_email: `${client.EMAIL}`,
          client_phone: `${this.clientPhone}`,
          customer_id: this.customer,
          room_id: this.roomId,
        }).then(() => {
          self.$emit('update-client-data', client, self.roomId)
        })
    },
    saveLead() {
      let self = this
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let data = {
          customer_id: this.customer,
          project_id: this.projectId,
          name: this.form.name,
          lastname: this.form.lastName,
          email: this.form.email,
          phone: this.clientPhone,
          source: 'WHATSAPP',
        }
        if (!this.hideDni) {
          if (this.form.dni) {
            data.dni = this.form.rut
          } else {
            data.rut = this.form.rut
          }
        }
        console.log('Guardando cliente', data)
        MobysuiteService.createLead(data).then(res => {
          console.log('Cliente guardado', res)
          self.updateRoom({
            ID_CLIENTE: null,
            NOMBRE: data.name,
            APELLIDO: data.lastname,
            EMAIL: data.email,
          })
          self.form = {
            dni: this.customerCountry == 1 ? false : true,
            name: '',
            lastName: '',
            rut: null,
            email: '',
          }
          self.$v.form.$reset()
          self.getClients()
        })
      }
    },
  },
}
</script>
